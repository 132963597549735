<template>
  <div class="rounded border p-3 flex flex-col shadow-sm">
    <span class="text-sm mb-3" v-t="'imagery_video_screen.still_uploading'" />
    
    <span>{{ $t('imagery_video_screen.upload_progress', { percent: progress }) }}</span>

    <div 
      class="h-1 bg-secondary rounded w-32" 
      :style="{
        'width': `${progress}%`
      }"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UploadProgressBanner',

  computed: {
    ...mapState(['files']),

    progress () {
      let files = this.files.filter(f => f.handled).map(file => file.progress)
      return files.length > 0 ? Math.min(...files) : 0
    }
  }
}
</script>
