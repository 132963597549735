<template>
  <div class="w-full max-w-sm h-full flex flex-col items-start justify-between">

    <div class="w-full mb-6 p-3 bg-gray-300 rounded-lg" v-if="valuationType === 'physical'">
      <p class="text-sm inline italic">{{ $t('pick_preferred') }}
        <span @click="setScreen('preferred-times')" class="text-sm border-b border-black border-dashed italic cursor-pointer">
          {{ $t('preferred_appointment_time') }}
          <icon name="arrow_right" class="inline w-6 h-6" />
        </span>
      </p>
    </div>
    
    <h2 class="border-b mb-4" v-t="'features_screen.heading'" />
    <div class="w-full flex-col md:flex-row flex mb-6">
      <div class="w-full mr-10">
        <div v-for="(feature, index) in $t('features_screen.features').slice(0, 5)" :key="index" class="flex items-center mb-4">
          <check-wrapper class="mr-2">
            <input v-model="features" type="checkbox" :id="`point-1-${index}`" :value="feature" />
          </check-wrapper>
          <label class="text-sm" :for="`point-1-${index}`">{{ feature }}</label>
        </div>
      </div>

      <div class="w-full">
        <div v-for="(feature, index) in $t('features_screen.features').slice(5, 10)" :key="index" class="flex items-center mb-4">
          <check-wrapper class="mr-2">
            <input v-model="features" type="checkbox" :id="`point-2-${index}`" :value="feature" />
          </check-wrapper>
          <label class="text-sm" :for="`point-2-${index}`">{{ feature }}</label>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col mb-6">
      <p class="mb-1 text-sm" v-t="'features_screen.consideration'" />
      <textarea 
        v-model="notes" 
        class="input w-full resize-none" 
        rows="4"
        :placeholder="$t('features_screen.notes_placeholder')"
      ></textarea>
      <error field="notes" />
    </div>

    <div class="w-full max-w-sm mx-auto flex justify-center">
      <button @click="setScreen('general-information')" class="btn bg-secondary btn-text-color w-full flex items-center justify-center mr-4">
        {{ $t('back') }}
      </button>
      <button @click="setScreen('imagery-video')" class="btn bg-secondary btn-text-color w-full flex items-center justify-center">
        {{ $t('next') }} <icon name="arrow_right" class="w-8 h-8" />
      </button>
    </div>
  </div>
</template>

<script>
import CheckWrapper from '@/components/CheckWrapper'
import Error from '@/components/Error'
import Icon from '@/components/Icon'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Features',

  components: {
    Icon,
    CheckWrapper,
    Error
  },

  computed: {
    ...mapState({
      valuationType: state => state.valuationType,
      propertyFeatures: state => state.features,
      propertyNotes: state => state.notes,
    }),

    features: {
      get () {
        return this.propertyFeatures
      },
      set (value) {
        this.setFeatures(value)
      }
    },

    notes: {
      get() {
        return this.propertyNotes
      },
      set (value) {
        this.setNotes(value)
      }
    }
  },

  methods: {
    ...mapActions([
      'setScreen',
      'setFeatures',
      'setNotes',
    ])
  }
}
</script>
