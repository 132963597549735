<template>
  <div class="font-sans leading-6 container mx-auto px-6" @click.stop>
    <div class="text-right pt-6 pb-3" v-if="ready && modal">
      <span @click="$emit('close')" class="text-white text-3xl cursor-pointer">&times;</span>
    </div>
    <valuation-journey v-if="ready && !unavailable" :headless="headless" :modal="modal" />
    <div
      v-else-if="unavailable"
      class="
        w-full
        mx-auto
        flex flex-col
        items-center
        max-w-3xl
        overflow-hidden
      "
      :class="{ 'bg-white shadow-lg md:rounded-lg p-6': !headless || modal }"
    >
      <h1 class="text-lg font-bold mb-4">Unavailable</h1>
      <img class="mb-4" src="../assets/images/images_video.svg" width="100" />
      <span class="text-gray-500 mb-4">{{ alias }}</span>
      <p class="text-sm">
        Please make sure an engage instance is active using the alias in the
        above address.
      </p>
    </div>
    <div v-else class="w-full flex items-center justify-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Loader from "../components/Loader.vue";
import ValuationJourney from "./ValuationJourney.vue";
import { useByContrast, CONTRAST_VERY_LIGHT, CONTRAST_DARK } from '@/util'
import cssVars from 'css-vars-ponyfill'
export default {
  components: { ValuationJourney, Loader },
  props: {
    headless: {
      type: Boolean,
      required: true,
    },
    modal: {
      type: Boolean,
      required: true,
    },
    country: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      unavailable: false,
    }
  },
  computed: {
    ...mapState([
      'currentScreen', 
      'system', 
      'completedLead',
      'alias'
    ]),

    ...mapGetters([
      'formDataForBeacon'
    ]),
  },
  created() {
    this.fetchSystem().then(system => {
      if(document.title === 'Request Your FREE Property Valuation') {
        document.title = `${process.env.VUE_APP_TITLE} - ${system.name}`
      }

      if(this.country && system.countries.filter(f => f.code === this.country).length) {
        this.setCountry(this.country)
      }
      else if(system.countries.filter(f => f.code === 'GB').length === 0) {
        this.setCountry(system.countries[0].code)
      }

      this.addStyles(system)

      this.cssVarsPolyfill()

      this.ready = true
      this.registerCloseBeacon()

      this.registerGA(system.ga_tracking_id)

      this.registerGTM(system.gtm_container_id)
    })
    .catch(() => {
      this.unavailable = true
    })
  },
  methods: {
    ...mapActions(['setAlias', 'setUpHttp', 'fetchSystem', 'setCountry']),

    registerCloseBeacon() {
      if (navigator.sendBeacon) {
        window.addEventListener('unload', () => {
          if (! this.completedLead) {
            const host = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_ENGAGE_API_URL.replace('{alias}', this.alias) : 'http://test.engage-api.epropservices.com:8001'
            navigator.sendBeacon(host + '/api/analytics/log', this.formDataForBeacon)
          }
        })
      }
    },

    cssVarsPolyfill() {
      if(window.MSInputMethodContext && document.documentMode) {
        cssVars()
      }
    },

    registerGA (trackingid) {
      if(! window.ga && trackingid) {
        window.ga=window.ga||function(){(window.ga.q=window.ga.q||[]).push(arguments)};window.ga.l=+new Date;

        const script = document.createElement('script')
        script.async = true
        script.src = 'https://www.google-analytics.com/analytics.js'
        document.head.appendChild(script);
      }

      if(! window.ga) {
        return
      }

      window.ga('create', trackingid, 'auto')
      window.ga('send', 'event', 'engage', 'initialised')

      this.$watch('currentScreen', () => {
        window.ga('send', 'event', 'engage', 'pageChange', this.currentScreen)
      })
    },

    registerGTM (containerId) {
      if(! containerId) {
        return
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'})
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://www.googletagmanager.com/gtm.js?id=' + containerId + '&l=dataLayer'
      document.head.appendChild(script);

      const frame = document.createElement('iframe')
      frame.src = 'https://www.googletagmanager.com/ns.html?id=' + containerId
      frame.height = 0
      frame.width = 0
      frame.style.display = 'none'
      frame.style.visibility = 'hidden'

      const noscript = document.createElement('noscript')
      noscript.appendChild(frame)

      document.body.appendChild(noscript)
    },

    addStyles (system) {
      const rootStyle = document.createElement('style')
      rootStyle.textContent = `
        :root {
          --eprop-engage-primary-colour: ${system.style.colour1};
          --eprop-engage-secondary-colour: ${useByContrast(system.style.colour2, CONTRAST_VERY_LIGHT, '#DDDDDD', system.style.colour2)};
        }
      `
      document.head.appendChild(rootStyle)

      const shadowStyle = document.createElement('style')
      shadowStyle.textContent = `
        .header-text-color { color: ${ useByContrast(system.style.colour1, CONTRAST_DARK, 'black', 'white') }; }
        .header-indicator-color { background-color: ${ useByContrast(system.style.colour1, CONTRAST_DARK, 'black', 'white') }; }
        .btn-text-color, .option-color { color: ${ useByContrast(system.style.colour2, CONTRAST_DARK, 'black', 'white') }; }
      `
      this.$el.parentNode.appendChild(shadowStyle)
    }
  }
};
</script>