<template>
  <div>
    <h1 class="text-lg uppercase mb-3" v-t="'privacy_policy'" />
    <p class="whitespace-pre-line mb-12">{{ system.privacy_policy }}</p>

    <button
        @click="setScreen('property')" 
        class="btn bg-secondary btn-text-color flex items-center justify-center px-6"
    >
        {{ $t('back') }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Privacy',

  computed: {
    ...mapState(['system']),
  },

  methods: {
    ...mapActions(['setScreen'])
  }
}
</script>
