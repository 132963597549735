export default {
  valuationType(state) {
    const id = state.system.countries.filter(f => f.code === state.address.data.address_country)[0].valuation_type

    switch (id) {
      case 0:
        return 'virtual'
      case 1:
        return 'physical'
    }

    return 'all'
  },

  currentStep (state) {
    switch (state.currentScreen) {
      case 'property':
        return 1
      case 'general-information':
        return 2
      case 'features':
        return 3
      case 'imagery-video':
        return 4
      case 'preferred-times':
      case 'thank-you':
        return 5
      default: 
        return 0
    }
  },

  propertyScreenNeedsCompleting (state) {
    return state.address.data.address_name_number.length == 0 ||   
      state.address.data.address_town.length == 0 || 
      (state.address.data.address_country === 'GB' && state.address.data.address_postcode.length == 0) || 
      state.name.length == 0 || 
      state.phone.length == 0 || 
      state.email.length == 0
  },

  allFilesCompleted (state) {
    if (state.files.length === 0) {
      return true
    }

    return state.files.filter(f => f.progress === 100).length === state.files.length
  },

  dateTimesForLead (state) {
    let datesTimes = [];
    for (let date in state.selectedDates) {
      if (state.selectedDates.hasOwnProperty(date)) {
        datesTimes.push({
          date: date,
          times: state.selectedDates[date]
        })
      }
    }
    return datesTimes
  },

  filesForLead (state) {
    return state.files.map(file => {
      return {
        filename: file.file.name,
        path: file.fullpath
      }
    })
  },

  formDataForBeacon (state) {
    let payload = {
      type: state.type,
      name: state.name,
      phone: state.phone,
      email: state.email
    }

    let fd = new FormData()

    if (Object.keys(state.address).length > 0) {
      payload.address = state.address.formatted
    }

    for (let key in payload) {
      fd.append(key, payload[key])
    }

    return fd
  },

  address (state) {
    return [
      state.address.data.address_name_number,
      state.address.data.address_line2,
      state.address.data.address_line3,
      state.address.data.address_town,
      state.address.data.address_line4,
      state.address.data.address_postcode
    ].filter(e => e).join(', ');
  }
}
