<template>
  <div class="w-full max-w-sm h-full flex flex-col items-start justify-between">
    
    <div class="w-full mb-6 p-3 bg-gray-300 rounded-lg" v-if="valuationType === 'physical'">
      <p class="text-sm inline italic">{{ $t('pick_preferred') }}
        <span @click="setScreen('preferred-times')" class="text-sm border-b border-black border-dashed italic cursor-pointer">
          {{ $t('preferred_appointment_time') }}
          <icon name="arrow_right" class="inline w-6 h-6" />
        </span>
      </p>
    </div>

    <h2 class="border-b mb-4" v-t="'imagery_video_screen.heading'" />
    
    <p class="mb-2 text-xs" v-t="'imagery_video_screen.upload_many'" />

    <div class="w-full flex flex-col items-center justify-center mb-6 py-6 border-dashed bg-gray-200 border border-gray-400 rounded outline-none relative">
      <input type="file" multiple class="w-full h-full absolute inset-0 outline-none opacity-0 cursor-pointer" @change="handleFile" />
      <img src="../assets/images/images_video.svg" width="100px" height="50px" class="mb-2 h-full" />
      <span class="text-xs" v-t="'imagery_video_screen.choose_media'" />
    </div>

    <transition-group
      appear
      name="transform-x-and-fade"
      tag="div"
      v-if="files.length" class="w-full mb-6 flex flex-col"
    >
      <div v-for="file in files" :key="file.file.name" class="w-full flex items-center px-2 h-10 border-dashed bg-gray-200 border border-gray-400 rounded relative mb-2">
        <div @click="removeFile(file)" class="w-4 h-4 rounded-full border text-xs flex items-center justify-center btn-text-color bg-secondary absolute top-0 right-0 -mt-2 -mr-2 cursor-pointer">x</div>
        <div 
          class="h-1 bg-primary absolute bottom-0 left-0 rounded animate-width"
          :style="{
            'width': `${file.progress}%`
          }"
        ></div>
        <span class="text-xs">{{ file.file.name }}</span>
      </div>
    </transition-group>

    <div class="w-full flex max-w-sm mx-auto justify-center">
      <button @click="setScreen('features')" class="btn bg-secondary btn-text-color w-full flex items-center justify-center mr-4">
        {{ $t('back') }}
      </button>
      <button @click="setScreen('preferred-times')" class="btn bg-secondary btn-text-color w-full flex items-center justify-center">
        {{ $t('next') }} <icon name="arrow_right" class="w-8 h-8" />
      </button>
    </div>
  </div>
</template>

<script>
import { default as httpClient, CancelToken } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import Icon from '@/components/Icon'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ImageryVideo',

  components: {
    Icon
  },

  data () {
    return {
      awsS3V4: null
    }
  },

  computed: {
    ...mapState(['files', 'valuationType', 'http'])
  },

  methods: {
    ...mapActions([
      'setScreen', 
      'appendFiles', 
      'updateFileProgress',
      'updateFileHandled',
      'updateFileCancel',
      'updateFileFullPath',
      'removeFile',
    ]),
    handleFile (e) {
      let files = Array.call([], ...e.target.files).map(file => { 
        return { 
          file, 
          path: uuidv4(), 
          progress: 0, 
          handled: false, 
          cancel: null, 
          fullpath: null
        }
      })

      // Allows selecting of the same file if removed and then picked again.
      e.target.value = ''

      this.appendFiles(files)

      this.uploadFiles()
    },

    async getS3UploadAttributes () {
      if (this.awsS3V4) {
        return this.awsS3V4
      }

      const res = await this.http.get('storage')
      this.awsS3V4 = res.data
      
      return this.awsS3V4
    },

    async uploadFiles () {
      const s3 = await this.getS3UploadAttributes()
      this.files.filter(file => file.handled === false)
        .forEach(file => {
          this.updateFileHandled({ file, handled: true })
          let fd = new FormData()

          let key = s3.inputs.key.replace(
            '${filename}', 
            file.path + '.' + file.file.name.split('.').pop()
          )

          this.updateFileFullPath({ file, fullpath: key })

          for (let attr in s3.inputs) {
            if (attr === 'key') {
              fd.append('key', key)
            } else {
              fd.append(attr, s3.inputs[attr])
            }
          }

          fd.append('file', file.file)

          httpClient.post(s3.attributes.action, fd, {
            onUploadProgress: progressEvent => {
              this.updateFileProgress({
                file, 
                progress: Math.ceil(progressEvent.loaded / progressEvent.total * 100)
              })
            },
            cancelToken: new CancelToken(cancel => {
              this.updateFileCancel({ file, cancel })
            })
          }).catch(err => {
            if (err.response && err.response.status === 403) {
              this.updateFileHandled({ file, handled: false })
              this.updateFileProgress({ file, progress: 0 })
              this.awsS3V4 = null
              this.uploadFiles()
            }
          })
        })
    }
  }
}
</script>

<style scoped>
.animate-width {
  transition: width .3s
}
</style>
