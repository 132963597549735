var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-full flex flex-col items-start justify-between"},[_c('div',{staticClass:"w-full flex md:flex-row flex-col items-start justify-between"},[_c('div',{staticClass:"w-full flex flex-col items-start",class:{
        'opacity-25': _vm.calendarLoaded && _vm.availabilityCalendar.length === 0
      }},[_c('h2',{directives:[{name:"t",rawName:"v-t",value:('preferred_times_screen.heading'),expression:"'preferred_times_screen.heading'"}],staticClass:"border-b mb-4"}),_c('p',{directives:[{name:"t",rawName:"v-t",value:('preferred_times_screen.select_multiple'),expression:"'preferred_times_screen.select_multiple'"}],staticClass:"text-sm mb-6"})]),(!_vm.allFilesCompleted)?_c('upload-progress-banner',{staticClass:"w-full md:w-auto mb-4 flex-shrink-0"}):_vm._e()],1),_c('div',{staticClass:"w-full flex-col md:flex-row flex mb-4 border-b pb-3"},[_c('calendar',{staticClass:"w-full md:mr-10",class:{
        'opacity-25': _vm.calendarLoaded && _vm.availabilityCalendar.length === 0
      },attrs:{"disabled":_vm.availabilityCalendar.length === 0},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('div',{staticClass:"w-full h-full flex flex-col items-start mt-5"},[_c('h2',{directives:[{name:"t",rawName:"v-t",value:('preferred_times_screen.times_heading'),expression:"'preferred_times_screen.times_heading'"}],staticClass:"border-b mb-4",class:{
          'opacity-25': _vm.calendarLoaded && _vm.availabilityCalendar.length === 0
        }}),_c('available-time-slots',{staticClass:"w-full -m-2",attrs:{"calendarLoaded":_vm.calendarLoaded,"date":_vm.date},model:{value:(_vm.selectedDates[_vm.date.toFormat('yyyy-MM-dd')]),callback:function ($$v) {_vm.$set(_vm.selectedDates, _vm.date.toFormat('yyyy-MM-dd'), $$v)},expression:"selectedDates[date.toFormat('yyyy-MM-dd')]"}})],1)],1),_c('div',{staticClass:"w-full flex flex-col items-center mb-6",class:{
      'opacity-25': _vm.calendarLoaded && _vm.availabilityCalendar.length === 0
    }},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('preferred_times_screen.selected_times_heading'),expression:"'preferred_times_screen.selected_times_heading'"}],staticClass:"text-sm"}),_vm._l((_vm.selectedDates),function(times,date){return _c('div',{key:date},[(times.length)?[_c('span',{staticClass:"text-sm font-bold"},[_vm._v(_vm._s(_vm.formatDate(date)))]),_vm._v(" "),_c('span',{staticClass:"text-sm"},[_vm._v("- "+_vm._s(times.join(', ')))])]:_vm._e()],2)}),(Object.keys(_vm.validDates).length === 0)?_c('span',{directives:[{name:"t",rawName:"v-t",value:('preferred_times_screen.any_time'),expression:"'preferred_times_screen.any_time'"}],staticClass:"text-sm font-bold"}):_vm._e()],2),_c('div',{staticClass:"w-full max-w-sm mx-auto flex justify-center"},[_c('button',{staticClass:"btn bg-secondary btn-text-color w-full flex items-center justify-center mr-4",on:{"click":function($event){return _vm.setScreen('imagery-video')}}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")]),_c('button',{staticClass:"btn bg-secondary btn-text-color w-full flex items-center justify-center",attrs:{"disabled":_vm.allFilesCompleted === false || _vm.sendingLead},on:{"click":_vm.submitLead}},[_vm._v(" "+_vm._s(_vm.$t('send'))+" "),_c('icon',{staticClass:"w-8 h-8",attrs:{"name":"arrow_right"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }