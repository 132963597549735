<template>
  <div class="w-full h-full flex flex-col items-start justify-between">
    <div class="w-full flex md:flex-row flex-col items-start justify-between">
      <div 
        class="w-full flex flex-col items-start"
        :class="{
          'opacity-25': calendarLoaded && availabilityCalendar.length === 0
        }"
      >
        <h2 class="border-b mb-4" v-t="'preferred_times_screen.heading'" />
        <p class="text-sm mb-6" v-t="'preferred_times_screen.select_multiple'" />
      </div>
      <upload-progress-banner v-if="!allFilesCompleted" class="w-full md:w-auto mb-4 flex-shrink-0" />
    </div>

    <div class="w-full flex-col md:flex-row flex mb-4 border-b pb-3">
      <calendar 
        v-model="date"
        class="w-full md:mr-10" 
        :disabled="availabilityCalendar.length === 0"
        :class="{
          'opacity-25': calendarLoaded && availabilityCalendar.length === 0
        }"
      />
      <div class="w-full h-full flex flex-col items-start mt-5">
        <h2 
          class="border-b mb-4"
          :class="{
            'opacity-25': calendarLoaded && availabilityCalendar.length === 0
          }"
          v-t="'preferred_times_screen.times_heading'"
        />
        <available-time-slots 
          :calendarLoaded="calendarLoaded" 
          :date="date"
          v-model="selectedDates[date.toFormat('yyyy-MM-dd')]" 
          class="w-full -m-2" 
        />
      </div>
    </div>

    <div 
      class="w-full flex flex-col items-center mb-6"
      :class="{
        'opacity-25': calendarLoaded && availabilityCalendar.length === 0
      }"
    >
      <span class="text-sm" v-t="'preferred_times_screen.selected_times_heading'" />
      <div v-for="(times, date) in selectedDates" :key="date">
        <template v-if="times.length">
          <span class="text-sm font-bold">{{ formatDate(date) }}</span> <span class="text-sm">- {{ times.join(', ') }}</span>
        </template>
      </div>
      <span
        class="text-sm font-bold"
        v-if="Object.keys(validDates).length === 0"
        v-t="'preferred_times_screen.any_time'"
      />
    </div>

    <div class="w-full max-w-sm mx-auto flex justify-center">
      <button @click="setScreen('imagery-video')" class="btn bg-secondary btn-text-color w-full flex items-center justify-center mr-4">
        {{ $t('back') }}
      </button>
      <button 
        :disabled="allFilesCompleted === false || sendingLead"
        @click="submitLead" class="btn bg-secondary btn-text-color w-full flex items-center justify-center"
      >
        {{ $t('send') }} <icon name="arrow_right" class="w-8 h-8" />
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import Calendar from '@/components/Calendar'
import AvailableTimeSlots from '@/components/AvailableTimeSlots'
import UploadProgressBanner from '@/components/UploadProgressBanner'
import { DateTime } from 'luxon'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'BookAnAppointment',

  components: {
    Icon,
    Calendar,
    AvailableTimeSlots,
    UploadProgressBanner
  },

  data () {
    return {
      selectedDates: {
        [DateTime.utc().plus({ days: 1 }).toFormat('yyyy-MM-dd')] : []
      },
      date: DateTime.utc().plus({ days: 1 }),
      calendarLoaded: false
    }
  },

  activated () {
    if (this.calendarLoaded === false) {
      this.fetchAvailabilityCalendar().then(() => {
        this.calendarLoaded = true
      }).catch(() => {
        this.calendarLoaded = false
      })
    }
  },

  computed: {
    ...mapGetters(['allFilesCompleted']),
    ...mapState([
      'sendingLead',
      'availabilityCalendar'
    ]),
    validDates () {
      let dates = {}
      for (let date in this.selectedDates) {
        if (this.selectedDates.hasOwnProperty(date)) {
          if (Array.isArray(this.selectedDates[date]) && this.selectedDates[date].length > 0) {
            dates[date] = this.selectedDates[date]
          }
        }
      }
      return dates
    }
  },

  methods: {
    ...mapActions([
      'setScreen', 
      'setSelectedDates', 
      'submitLead',
      'fetchAvailabilityCalendar'
    ]),

    formatDate (date) {
      return DateTime.fromISO(date).toFormat('EEEE d MMMM')
    }
  },

  watch: {
    date (d) {
      if (! this.selectedDates.hasOwnProperty(d.toFormat('yyyy-MM-dd'))) {
        this.$set(this.selectedDates, d.toFormat('yyyy-MM-dd'), [])
      }
    },

    validDates (dates) {
      this.setSelectedDates(dates)
    }
  }
}
</script>
