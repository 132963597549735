export default {
  locale: 'en', // for future use
  alias: 'test',
  http: null,
  currentScreen: 'property',
  completedLead: false,
  sendingLead: false,
  validationErrors: {},
  system: null,
  loadingAddresses: false,
  addresses: [],
  availabilityCalendar: [],
  office: {},
  address: {
    data: {
      address_line2: '',
      address_line3: '',
      address_line4: '',
      address_name_number: '',
      address_postcode: '',
      address_town: '',
      address_country: 'GB'
    },
    formatted: ''
  },
  type: 'sales',
  postcode: '',
  name: '',
  phone: '',
  email: '',
  valuationType: 'physical',
  bedrooms: 1,
  bathrooms: 1,
  receptions: 1,
  propertyType: 1,
  yearBuilt: '',
  plotSize: 1,
  condition: 1,
  alterations: '',
  features: [],
  notes: '',
  files: [],
  selectedDates: {}
}
