<template>
  <div>
    <div class="w-full mx-auto flex flex-col md:flex-row overflow-hidden" :class="{'md:bg-white md:shadow-lg rounded-lg': !headless || modal}">
      <div class="w-full md:w-1/2 lg:w-1/3 md:bg-primary header-text-color md:p-12 flex text-center flex-col items-center" v-if="!headless">
        <img :src="system.style.logo" alt="system.name" class="max-w-xs mb-3 md:mb-12" />
        <h1 class="md:hidden mb-6" v-t="'valuation_journey.sidebar.heading'" />
        <p class="mb-6 hidden md:block" v-t="'valuation_journey.sidebar.paragraph1' + (system.enabled_departments.sales && !system.enabled_departments.lettings ? '_sales' : (!system.enabled_departments.sales && system.enabled_departments.lettings ? '_lettings' : ''))" />
        <p class="hidden md:block" v-t="'valuation_journey.sidebar.paragraph2'" v-if="valuationType !== 'physical'" />
      </div>

      <div class="w-full overflow-hidden" :class="{'md:w-2/3 bg-white shadow-lg rounded-lg': !headless, 'p-6 md:p-12': !headless || modal}">
        <validation-errors v-if="Object.keys(validationErrors).length > 0" class="w-full mb-6" />
        <transition name="transform-x-and-fade" mode="out-in" appear>
          <keep-alive>
            <component :is="currentScreen" />
          </keep-alive>
        </transition>
      </div>
    </div>

    <div class="text-center my-6" v-if="!headless">
      <small class="hover:underline text-xs cursor-pointer" @click="setScreen('privacy')" v-t="'privacy_policy'" />
    </div>
  </div>
</template>

<script>
import Property from '@/screens/Property'
import GeneralInformation from '@/screens/GeneralInformation'
import Features from '@/screens/Features'
import ImageryVideo from '@/screens/ImageryVideo'
import PreferredTimes from '@/screens/PreferredTimes'
import ThankYou from '@/screens/ThankYou'
import Privacy from '@/screens/Privacy'
import ValidationErrors from '@/components/ValidationErrors'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'ValuationJourney',

  components: {
    Property,
    GeneralInformation,
    Features,
    ImageryVideo,
    PreferredTimes,
    ThankYou,
    Privacy,
    ValidationErrors
  },

  props: {
    headless: {
      type: Boolean,
      required: true
    },
    modal: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapState([
      'validationErrors',
      'currentScreen',
      'system',
    ]),
    ...mapGetters(['valuationType'])
  },

  methods: {
    ...mapActions(['setValidationErrors', 'setScreen'])
  },

  watch: {
    currentScreen (screen) {
      // Clear validation errors when navigating from the property screen.
      // Very simple logic here, this may be insufficent in the future,
      // so just leaving a friendly comment! Validation errors navigate you to
      // the property screen so this is reasonable on that basis.
      if (screen === 'general-information') {
        this.setValidationErrors({})
      }

      window.scrollTo(0, 0)
    }
  }
}
</script>
