<template>
  <div class="flex flex-col items-center">
    <div class="w-full border-b py-2 flex justify-center">
      <div class="w-full max-w-xs flex items-center justify-around">
        <div 
          class="p-3 cursor-pointer" 
          @click="current = current.minus({ months: 1 })"
        >
          <icon name="chevron_left" class="w-4 h-4" />
        </div>
        <span class="w-32 text-center font-bold">{{ current.toFormat('MMMM y') }}</span>
        <div 
          class="p-3 cursor-pointer" 
          @click="current = current.plus({ months: 1 })"
        >
          <icon name="chevron_right" class="w-4 h-4" />
        </div>
      </div>
    </div>

    <div class="w-full flex items-center justify-between border-b">
      <span
        class="m-1 p-2 w-8 h-8 text-xs text-center"
        v-for="day in daysOfWeek"
        :key="day"
        v-html="day"
      />
    </div>

    <div
      v-for="(week, wIndex) in weeks" 
      :key="'week-' + wIndex"  
      class="w-full flex items-center justify-between"
    >
      <span
        v-for="(day, dIndex) in week"
        :key="`d-${dIndex}-w${wIndex}}`"
        class="text-sm m-1 p-2 w-8 h-8 text-center flex items-center justify-center rounded-full cursor-pointer"
        :class="{
          'text-gray-400': day < now || current.month !== day.month,
          'bg-secondary option-color': value.toFormat('DD') === day.toFormat('DD')
        }"
        @click="selectDay(day)"
      >
        {{ day.toFormat('d') }}
      </span>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import { DateTime } from 'luxon'

export default {
  name: 'Calendar',

  props: {
    disabled: {
      type: Boolean
    },
    value: {
      type: DateTime,
      default: DateTime.utc()
    }
  },

  components: {
    Icon
  },

  data () {
    return {
      current: DateTime.utc(),
      now: DateTime.utc()
    }
  },

  computed: {
    startDate () {
      const startOfMonth = this.current.startOf('month')
      return startOfMonth.minus({ days: startOfMonth.weekday - 1 })
    },

    daysOfWeek () {
      const start = this.current.startOf('week')
      const daysOfWeek = []
      for (let i = 0; i <= 6; i++) {
        daysOfWeek.push(
          start.plus({ days: i }).toFormat('EEE')
        )
      }
      return daysOfWeek
    },

    weeks () {
      const days = 6 * 7
      const weeks = [[],[],[],[],[],[]]
      for (let i = 0; i < days; i++) {
        const index = Math.floor(i / 7)
        weeks[index].push(
          this.startDate.plus({ days: i })
        )
      }
      return weeks
    }
  },

  methods: {
    selectDay (day) {
      if (this.disabled) {
        return
      }

      if (day > this.now && this.current.month == day.month) {
        this.$emit('input', day)
      }
    }
  }
}
</script>

<style scoped>
.halo {
  box-shadow: 0px 0px 0px 4px #5BCEAE50
}
</style>
