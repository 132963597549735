<template>
  <div class="w-full h-full flex flex-wrap items-start justify-between">
    <div class="w-full lg:w-1/2 lg:pr-6 lg:border-r border-gray-300">
      <div v-if="availableValuationType === 'all'" class="w-full mb-10 flex flex-col">
        <span class="mb-4" v-t="'property_screen.valuation_type'" />
        <div class="w-full flex items-center">
          <div class="flex items-center mr-6 w-48">
            <radio-wrapper class="mr-2">
              <input v-model="valuationType" value="physical" type="radio" id="physical-valuation-option"/>
            </radio-wrapper>
            <label class="text-sm cursor-pointer" for="physical-valuation-option" v-t="'physical_valuation'" />
          </div>

          <div class="flex items-center w-48">
            <radio-wrapper class="mr-2">
              <input v-model="valuationType" value="virtual" type="radio" id="virtual-valuation-option"/>
            </radio-wrapper>
            <label class="text-sm cursor-pointer" for="virtual-valuation-option"  v-t="'virtual_valuation'" />
          </div>
        </div>
      </div>
      <div class="w-full mb-6 flex flex-col">
        <span class="mb-4" v-t="'property_screen.looking_to'" />
        <div class="w-full flex items-center">
          <div class="flex items-center mr-6 w-48" :class="{ 'opacity-25' : system.enabled_departments.sales === false }">
            <radio-wrapper class="mr-2">
              <input v-model="type" value="sales" type="radio" id="sell-property-option" :disabled="system.enabled_departments.sales === false" />
            </radio-wrapper>
            <label class="text-sm cursor-pointer" for="sell-property-option" v-t="'property_screen.sell'" />
          </div>

          <div class="flex items-center w-48" :class="{ 'opacity-25' : system.enabled_departments.lettings === false }">
            <radio-wrapper class="mr-2">
              <input v-model="type" value="lettings" type="radio" id="let-property-option" :disabled="system.enabled_departments.lettings === false" />
            </radio-wrapper>
            <label class="text-sm cursor-pointer" for="let-property-option"  v-t="'property_screen.let'" />
          </div>
        </div>
      </div>

      <div class="w-full flex flex-col items-start mb-6">
        <div class="w-full max-w-sm h-full flex flex-col items-start justify-between mx-auto" v-if="system.countries.length > 1">
          <div class="w-full flex flex-col mb-6">
            <label class="text-sm mb-1" v-t="'intro_screen.country'" />
            <select-wrapper>
              <select v-model="country">
                <option 
                  v-for="country in system.countries" 
                  :key="country.code" 
                  :value="country.code" 
                  v-t.preserve="`intro_screen.countries.${country.code}`"
                />
              </select>
            </select-wrapper>
          </div>
        </div>

        <p class="mb-6 text-sm" v-if="manualAddressEntry || !system.has_address_lookup || country !== 'GB'">
          {{ $t('addresses.manual') }} <span class="font-bold text-sm">* {{ $t('required_field') }}.</span>
        </p>

        <p class="mb-6 text-sm" v-else v-t="'addresses.postcode_lookup'" />
        
        <div v-if="system.has_address_lookup && country === 'GB'" class="w-full flex flex-col">
          <address-selector v-if="!manualAddressEntry" />
          <error field="postcode" />
        </div>

        <custom-address v-if="manualAddressEntry || !system.has_address_lookup || country !== 'GB'" />

        <span 
          v-if="system.has_address_lookup && country === 'GB'"
          @click="manualAddressEntry = !manualAddressEntry" 
          class="self-end text-sm underline text-primary text-center cursor-pointer"
          v-t="`addresses.${!manualAddressEntry ? 'cant_find' : 'back_to_lookup' }`"
        />
      </div>
    </div>

    <div class="w-full lg:w-1/2 lg:pl-6">
      <div class="w-full">
        <div class="w-full flex flex-col mb-6">
          <label class="text-sm mb-1" for="name" v-t="'property_screen.name'" />
          <input v-model="name" type="text" class="input w-full" id="name" name="name" />
          <error field="name" />
        </div>

        <div class="w-full flex flex-col mb-6">
          <label class="text-sm mb-1" for="phone" v-t="'property_screen.phone'" />
          <input v-model="phone" type="tel" class="input w-full" id="phone" name="phone" />
          <error field="phone" />
        </div>

        <div class="w-full flex flex-col mb-6">
          <label class="text-sm mb-1" for="email" v-t="'property_screen.email'" />
          <input v-model="email" type="email" class="input w-full" id="email" name="email" />
          <error field="email" />
        </div>
      </div>

      <div class="w-full max-w-sm mx-auto flex justify-center">
        <button
          v-if="system.experience_id === 1"
          :disabled="propertyScreenNeedsCompleting" 
          @click="setScreen('general-information')" 
          class="btn bg-secondary btn-text-color w-full flex items-center justify-center"
        >
          {{ $t('next') }} <icon name="arrow_right" class="w-8 h-8" />
        </button>

        <button 
          v-else
          :disabled="propertyScreenNeedsCompleting || sendingLead"
          @click="submitLead" class="btn bg-secondary btn-text-color w-full flex items-center justify-center"
        >
          {{ $t('send') }} <icon name="arrow_right" class="w-8 h-8" />
      </button>
      </div>
    </div>
  </div>
</template>

<script>
import RadioWrapper from '@/components/RadioWrapper'
import AddressSelector from '@/components/AddressSelector'
import CustomAddress from '@/components/CustomAddress'
import Error from '@/components/Error'
import Icon from '@/components/Icon'
import { mapActions, mapState, mapGetters } from 'vuex'
import SelectWrapper from '../components/SelectWrapper.vue'

export default {
  name: 'Property',

  components: {
    RadioWrapper,
    AddressSelector,
    CustomAddress,
    Icon,
    Error,
    SelectWrapper
  },

  data () {
    return {
      manualAddressEntry: false
    }
  },

  computed: {
    ...mapGetters(['propertyScreenNeedsCompleting', 'valuationType']),
    ...mapGetters({
      propertyScreenNeedsCompleting: 'propertyScreenNeedsCompleting',
      availableValuationType : 'valuationType'
    }),
    ...mapState({
      system: state => state.system,
      propertyType: state => state.type,
      aboutName: state => state.name,
      aboutPhone: state => state.phone,
      aboutEmail: state => state.email,
      address: state => state.address,
      propertyValuationType: state => state.valuationType,
      sendingLead: state => state.sendingLead
    }),

    type: {
      get () {
        return this.propertyType
      },
      set (value) {
        this.setType(value)
      }
    },

    name: {
      get () {
        return this.aboutName
      },
      set (value) {
        this.setName(value)
      }
    },

    phone: {
      get () {
        return this.aboutPhone
      },
      set (value) {
        this.setPhone(value)
      }
    },

    email: {
      get () {
        return this.aboutEmail
      },
      set (value) {
        this.setEmail(value)
      }
    },

    country: {
      get () {
        return this.address.data.address_country
      },
      set (value) {
        this.setCountry(value)
      }
    },

    valuationType: {
      get () {
        return this.propertyValuationType
      },
      set (value) {
        this.setValuationType(value)
      }
    }
  },

  methods: {
    ...mapActions([
      'setType',
      'setScreen',
      'setName',
      'setPhone',
      'setEmail',
      'setCountry',
      'setMarketing',
      'setValuationType',
      'submitLead'
    ]),
  }, 
}
</script>
