<template>
  <div v-if="$slots.default">
    <span @click="modalOpen = true">
      <slot @close="modalOpen = false" />
    </span>
    <transition name="fade">
      <div class="fixed inset-0 bg-black bg-opacity-75 z-1001" v-if="modalOpen" @click="modalOpen = false">
        <div class="w-full h-screen overflow-auto">
          <div class="flex items-center justify-center min-h-screen">
            <page :headless="headless" :country="country" :modal="true" @close="modalOpen = false" />
          </div>
        </div>
      </div>
    </transition>
  </div>
  <page v-else :headless="headless" :country="country" :modal="false" />
</template>

<script>
import store from "./store";
import i18n from '@/i18n'
import { mapActions } from 'vuex'
import 'svgxuse'
import Vue from 'vue';
import Page from './pages/Page.vue';

Vue.use(i18n)

export default {
  components: { Page },
  name: 'app',
  store,
  i18n,

  props: {
    alias: {
      type: String,
      default: window.location.hostname.split('.').shift()
    },
    headless: {
      type: Boolean,
      default: false
    },
    country: {
      type: String,
      default: 'GB'
    }
  },

  data () {
    return {
      modalOpen: window.location.hash === "#engage"
    }
  },

  created () {
    if(!this.alias) {
      this.unavailable = true
      return
    }
    this.setAlias(this.alias)
    this.setUpHttp()
  },
  methods: {
    ...mapActions(['setAlias', 'setUpHttp']),
  }
}
</script>

<style>
@import './assets/css/tailwind.css';
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
