<template>
  <div class="w-full flex flex-col">
    <div class="flex flex-col mb-6">
      <label class="uppercase text-xs mb-2">{{ $t('addresses.address_line', { num: 1 }) }}: *</label>
      <input class="input" v-model="addr1" />
      <error field="address_name_number" />
    </div>

    <div class="flex flex-col mb-6">
      <label class="uppercase text-xs mb-2">{{ $t('addresses.address_line', { num: 2 }) }}:</label>
      <input class="input" v-model="addr2" />
    </div>

    <div class="flex flex-col mb-6">
      <label class="uppercase text-xs mb-2">{{ $t('addresses.town_city') }}: *</label>
      <input class="input" v-model="addrTownCity"  />
      <error field="address_town" />
    </div>

    <div class="flex flex-col mb-6">
      <label class="uppercase text-xs mb-2">{{ $t('addresses.county_state') }}:</label>
      <input class="input" v-model="addrCountyState" />
    </div>

    <div class="flex flex-col mb-4">
      <label class="uppercase text-xs mb-2">{{ $t('addresses.postcode') }}: <span v-if="addrCountry === 'GB'">*</span></label>
      <input class="input" v-model="addrPostalCode" />
      <error field="address_postcode" />
    </div>
  </div>
</template>

<script>
import Error from '@/components/Error'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CustomAddress',

  components: {
    Error
  },

  computed: {
    ...mapState({
      addressData: state => state.address.data
    }),

    addr1: {
      get () { return this.addressData.address_name_number },
      set (value) { this.setAddressField({ address_name_number: value }) }
    },

    addr2: {
      get () { return this.addressData.address_line2 },
      set (value) { this.setAddressField({ address_line2: value }) }
    },

    addrTownCity: {
      get () { return this.addressData.address_town },
      set (value) { this.setAddressField({ address_town: value }) }
    },

    addrCountyState: {
      get () { return this.addressData.address_line4 },
      set (value) { this.setAddressField({ address_line4: value }) }
    },

    addrPostalCode: {
      get () { return this.addressData.address_postcode },
      set (value) { this.setAddressField({ address_postcode: value }) }
    },

    addrCountry: {
      get () { return this.addressData.address_country },
    }
  },

  methods: {
    ...mapActions (['setAddress']),
    setAddressField (field) {
      const data = { ...this.addressData, ...field }
  
      const formatted = [
        data.address_name_number, 
        data.address_line2, 
        data.address_line3, 
        data.address_line4, 
        data.address_town, 
        data.address_postcode
      ].filter(part => part && part.length > 0).join(', ')

      this.setAddress({ data, formatted })
    }
  },
}
</script>
