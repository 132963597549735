<template>
  <div class="input flex relative">
    <slot />
    <icon name="chevron_down" class="absolute w-4 h-4 text-black right-0 mr-3 center-y pointer-events-none" />
  </div>
</template>

<style scoped>
select {
  @apply w-full h-full pr-6 appearance-none bg-white outline-none
}
</style>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'SelectWrapper',
  components: {
    Icon
  }
}
</script>