function getContrastYIQ (hexcolor) {
  hexcolor = hexcolor.replace('#', '')
  const r = parseInt(hexcolor.substr(0, 2), 16)
  const g = parseInt(hexcolor.substr(2, 2), 16)
  const b = parseInt(hexcolor.substr(4, 2), 16)
  return ((r * 299) + (g * 587) + (b * 114)) / 1000
}

function useByContrast(hexcolor, yiq, option1, option2) {
  return getContrastYIQ(hexcolor) >= yiq ? option1 : option2
}

function find (arr, cb) {
  for (let i = 0; i < arr.length; i++) {
    if (cb(arr[i])) {
      return arr[i]
    }
  }
}

export const CONTRAST_VERY_LIGHT = 245
export const CONTRAST_DARK = 128

export {
  getContrastYIQ,
  useByContrast,
  find
}
