<template>
  <div v-if="field && error" class="text-red text-xs py-1">
    {{ error }}
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Error',

  props: ['field'],

  computed: {
    ...mapState(['validationErrors']),

    error () {
      return this.validationErrors[this.field] !== undefined 
        ? this.validationErrors[this.field][0]
        : null
    }
  }
}
</script>