import axios from 'axios'

export default {
  setAlias ({ commit }, alias) {
    commit('setAlias', alias)
  },

  setUpHttp({ commit, state }) {
    const host = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_ENGAGE_API_URL.replace('{alias}', state.alias) : 'http://test.engage-api.epropservices.com:8001'
    const baseURL = host + '/api/'
    const client = axios.create({ baseURL })

    client.interceptors.response.use(res => res, error => {
      if (error.response && error.response.status === 422) {
        if (error.response.data.errors) {
          commit('setValidationErrors', error.response.data.errors)
        }
    
        commit('setScreen', 'property')
      }
    
      return Promise.reject(error)
    })

    commit('setHttp', client)
  },

  setScreen ({ commit }, screen) {
    commit('setScreen', screen)
  },

  setValidationErrors ({ commit }, errors) {
    commit('setValidationErrors', errors)
  },

  fetchAddressesForPostcode ({ commit, state }, postcode) {
    commit('setLoadingAddresses', true)
    commit('setValidationErrors', {})

    state.http.get('address-lookup', {
      params: { postcode }
    }).then(({ data }) => {
      commit('setAddresses', data)
      if (data[0]) {
        commit('setAddress', data[0])
      }
    }).finally(() => {
      commit('setLoadingAddresses', false)
    })
  },

  setCountry ({ commit }, country) {
    commit('setCountry', country)
  },

  setAddresses ({ commit }, addresses) {
    commit('setAddresses', addresses)
  },

  setPostcode ({ commit }, postcode) {
    commit('setPostcode', postcode)
  },

  setAddress ({ commit }, address) {
    commit('setAddress', address)
  },

  setType ({ commit }, type) {
    commit('setType', type)
  },

  setName ({ commit }, name) {
    commit('setName', name)
  },

  setPhone ({ commit }, phone) {
    commit('setPhone', phone)
  },

  setEmail ({ commit }, email) {
    commit('setEmail', email)
  },

  setBedrooms({ commit }, bedrooms) {
    commit('setBedrooms', bedrooms)
  },

  setBathrooms({ commit }, bathrooms) {
    commit('setBathrooms', bathrooms)
  },

  setReceptions({ commit }, receptions) {
    commit('setReceptions', receptions)
  },

  setPropertyType({ commit }, type) {
    commit('setPropertyType', type)
  },

  setYearBuilt({ commit }, year) {
    commit('setYearBuilt', year)
  },

  setPlotSize({ commit }, size) {
    commit('setPlotSize', size)
  },

  setCondition({ commit }, condition) {
    commit('setCondition', condition)
  },

  setAlterations({ commit }, alterations) {
    commit('setAlterations', alterations)
  },

  setFeatures ({ commit }, features) {
    commit('setFeatures', features)
  },

  appendFiles ({ commit }, files) {
    commit('appendFiles', files)
  },

  setNotes ({ commit }, notes) {
    commit('setNotes', notes)
  },

  updateFileProgress ({ commit }, payload) {
    commit('updateFileProgress', payload)
  },

  updateFileHandled ({ commit }, payload) {
    commit('updateFileHandled', payload)
  },

  updateFileCancel ({ commit }, payload) {
    commit('updateFileCancel', payload)
  },

  updateFileFullPath ({ commit }, payload) {
    commit('updateFileFullPath', payload)
  },

  removeFile ({ commit }, file) {
    commit('removeFile', file)
  },

  setSelectedDates ({ commit }, dates) {
    commit('setSelectedDates', dates)
  },

  setValuationType ({ commit }, type) {
    commit('setValuationType', type)
  },

  fetchSystem ({ commit, state }) {
    return new Promise((res, rej) => {
      state.http.get('system').then(({ data }) => {
        commit('setSystem', data)
        const sales = data.enabled_departments.sales
        const lettings = data.enabled_departments.lettings
        
        if (sales === false && lettings === true) {
          commit('setType', 'lettings')
        } else {
          commit('setType', 'sales')
        }

        res(data)
      }).catch(err => {
        rej(err)
      })
    })
  },

  fetchAvailabilityCalendar ({ commit, state }) {
    return new Promise((res, rej) => {
      state.http.get('calendar', {
        params: {
          rental: state.type === 'lettings' ? 1 : 0,
          address_town: state.address.data.address_town,
          address_line4: state.address.data.address_line4,
          address_postcode: state.address.data.address_postcode,
          address_country: state.address.data.address_country,
        }
      }).then(({ data }) => {
        commit('setAvailabilityCalendar', data.calendar)
        commit('setOffice', data.office)
        res()
      }).catch (() => {
        rej()
      })
    })
  },

  submitLead ({ state, getters, commit }) {
    commit('setValidationErrors', {})
    commit('setSendingLead', true)
    state.http.post('lead', {
      'valuation_type': state.valuationType === 'virtual' ? 0 : 1,
      'name': state.name,
      'email': state.email,
      'phone': state.phone,
      'rental': state.type === 'lettings' ? 1 : 0,
      'address_name_number': state.address.data.address_name_number,
      'address_line2': state.address.data.address_line2,
      'address_line3': state.address.data.address_line3,
      'address_town': state.address.data.address_town,
      'address_line4': state.address.data.address_line4,
      'address_postcode': state.address.data.address_postcode,
      'address_country': state.address.data.address_country,
      'bedrooms': state.bedrooms,
      'bathrooms': state.bathrooms,
      'reception_rooms': state.receptions,
      'property_type_id': state.propertyType,
      'year_built': state.yearBuilt,
      'land_size': state.plotSize,
      'condition_id': state.condition,
      'alterations': state.alterations,
      'notes': state.notes,
      'features': state.features,
      'preferred_times': getters.dateTimesForLead,
      'attachments': getters.filesForLead
    }).then((response) => {
      state.office = response.data.office
      commit('setCompletedLead', true)
      commit('setScreen', 'thank-you')
    }).finally(() => {
      commit('setSendingLead', false)
    })
  }
}