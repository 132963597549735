<template>
  <label>
    <slot></slot>
    <span>
      <icon name="tick" class="option-color w-4 h-4" />
    </span>
  </label>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'CheckWrapper',

  components: {
    Icon
  }
}
</script>

<style scoped>
input[type="checkbox"] {
  @apply absolute opacity-0 w-0 h-0
}

input[type="checkbox"] + span {
  @apply inline-block w-6 h-6 bg-white rounded border border-gray-500 cursor-pointer flex items-center justify-center
}

input[type="checkbox"] + span svg {
  @apply hidden
}

input[type="checkbox"]:checked + span {
  @apply text-white relative;
  background-color: var(--eprop-engage-secondary-colour);
}

input[type="checkbox"]:checked + span svg {
  @apply block
}

</style>