<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <g v-if="name === 'image'" fill="#111" class="nc-icon-wrapper">
        <path stroke="null" id="svg_2" d="M39.905 37.556H8.095a4.162 4.162 0 0 1-4.157-4.157V14.6a4.162 4.162 0 0 1 4.157-4.157h31.81a4.162 4.162 0 0 1 4.157 4.157V33.4a4.162 4.162 0 0 1-4.157 4.157zM8.095 11.529A3.076 3.076 0 0 0 5.022 14.6V33.4a3.076 3.076 0 0 0 3.073 3.072h31.81a3.076 3.076 0 0 0 3.073-3.072V14.6a3.076 3.076 0 0 0-3.073-3.072H8.095z"/>
        <path stroke="null" id="svg_3" d="M40.198 33.628L30.181 19.101a.299.299 0 0 0-.527 0l-5.456 7.913a.299.299 0 0 1-.526 0l-2.896-4.198a.299.299 0 0 0-.526 0l-7.456 10.812c-.144.21-.052.382.205.382h26.995c.257 0 .349-.172.204-.382zM16.502 18.364a4.384 4.384 0 1 0-4.384 4.375 4.379 4.379 0 0 0 4.384-4.375z"/>
    </g>
    
    <g v-if="name === 'document'" class="nc-icon-wrapper" stroke="none" fill="currentColor">
      <path d="M30.854 47.5a.959.959 0 0 1-.96-.96V35.03a.959.959 0 0 1 .96-.96h11.512a.96.96 0 0 1 0 1.919H31.813v10.553a.959.959 0 0 1-.959.959z"/>
      <path d="M30.854 47.5H11.03a.959.959 0 0 1-.96-.96V7.153a.959.959 0 0 1 .96-.96h31.336a.959.959 0 0 1 .96.96v27.877a.958.958 0 0 1-.282.678L31.532 47.219a.959.959 0 0 1-.678.281zM11.99 45.581h18.467l10.95-10.95V8.111H11.99z"/>
      <path d="M28.778 15.389H17.506a.96.96 0 0 1 0-1.92h11.272a.96.96 0 0 1 0 1.92z"/>
      <path d="M36.213 20.028H17.506a.96.96 0 0 1 0-1.919h18.707a.96.96 0 1 1 0 1.919z"/>
      <path d="M36.213 24.663H17.506a.96.96 0 0 1 0-1.918h18.707a.96.96 0 1 1 0 1.918z"/>
      <path d="M25.338 29.303h-7.832a.96.96 0 0 1 0-1.92h7.832a.96.96 0 1 1 0 1.92z"/>
      <path d="M5.634 42.954a.959.959 0 0 1-.96-.96V3.379A2.881 2.881 0 0 1 7.554.5H36.97a.96.96 0 0 1 0 1.919H7.553a.961.961 0 0 0-.96.959v38.617a.959.959 0 0 1-.959.96z"/>
    </g>
    
    <g v-if="name === 'no_entry'" class="nc-icon-wrapper" stroke="none" fill="currentColor">
      <path d="M24 .5A23.5 23.5 0 1 0 47.5 24 23.527 23.527 0 0 0 24 .5zm0 45.301A21.801 21.801 0 1 1 45.801 24 21.826 21.826 0 0 1 24 45.801z"/>
      <path d="M40.386 7.614a1.038 1.038 0 0 0-1.468 0L24 22.532 22.532 24 7.695 38.837a1.038 1.038 0 0 0 1.468 1.468L24 25.468 25.468 24 40.386 9.082a1.038 1.038 0 0 0 0-1.468z"/>
    </g>
  
    <g v-if="name === 'chevron_right'" class="nc-icon-wrapper" stroke="none" fill="currentColor">
      <path d="M13.473 47.5a1.443 1.443 0 0 1-1.044-2.44L32.532 24 12.429 2.94A1.443 1.443 0 1 1 14.517.947l21.054 22.057a1.443 1.443 0 0 1 0 1.993L14.517 47.053a1.44 1.44 0 0 1-1.044.447z"/>
    </g>
    
    <g v-if="name === 'arrow_right'" class="nc-icon-wrapper" stroke="none" fill="currentColor">
      <path d="M37.621 23.825l-8.039-8.039a.4.4 0 0 0-.566.567l7.309 7.307H14.368a.4.4 0 0 0 0 .8H36.42l-7.187 7.187a.4.4 0 0 0 .566.567l7.822-7.822a.4.4 0 0 0 0-.567z"/>
    </g>
    
    <g v-if="name === 'chevron_left'" class="nc-icon-wrapper" stroke="none" fill="currentColor">
      <path d="M34.527.5a1.443 1.443 0 0 1 1.044 2.44L15.468 24l20.103 21.06a1.443 1.443 0 0 1-2.088 1.993L12.429 24.996a1.443 1.443 0 0 1 0-1.993L33.483.947A1.44 1.44 0 0 1 34.527.5z"/>
    </g>
    
    <g v-if="name === 'tick'" class="nc-icon-wrapper" stroke="none" fill="currentColor">
      <path d="M16.296 40.691L.5 24.895l2.179-2.179 13.617 13.617L45.321 7.309 47.5 9.487 16.296 40.691z"/>
    </g>
    
    <g v-if="name === 'chevron_down'" class="nc-icon-wrapper" stroke="none" fill="currentColor">
      <path d="M47.5 13.47a1.44 1.44 0 0 0-2.44-1.04l-21.06 20.1-21.06-20.1a1.44 1.44 0 0 0-1.99 2.09l22.05 21.05a1.44 1.44 0 0 0 2 0l22.05-21.05a1.44 1.44 0 0 0 0.45-1.05z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
svg use {
  fill: currentColor;
  stroke: currentColor;
}
</style>