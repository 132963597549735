<template>
  <div class="w-full max-w-sm h-full flex flex-col items-start justify-between">

    <div class="w-full mb-6 p-3 bg-gray-300 rounded-lg" v-if="valuationType === 'physical'">
      <p class="text-sm inline italic">{{ $t('pick_preferred') }}
        <span @click="setScreen('preferred-times')" class="text-sm border-b border-black border-dashed italic cursor-pointer">
          {{ $t('preferred_appointment_time') }}
          <icon name="arrow_right" class="inline w-6 h-6" />
        </span>
      </p> 
    </div>

    <h2 class="border-b mb-4" v-t="'general_info_screen.heading'" />
    <div class="w-full flex flex-col items-start mb-6">
      <div class="w-full flex items-center justify-between">
        <div class="w-18 flex flex-col items-center">
          <span class="text-xs mb-1" v-t="'general_info_screen.bedrooms'" />
          <select-wrapper class="w-full">
            <select v-model="bedrooms">
              <option v-for="i in 10" :value="i" :key="i">
                {{ i === 10 ? `${i}+` : i }}
              </option>
            </select>
          </select-wrapper>
        </div>

        <div class="w-18 flex flex-col items-center">
          <span class="text-xs mb-1" v-t="'general_info_screen.bathrooms'" />
          <select-wrapper class="w-full">
            <select v-model="bathrooms">
              <option v-for="i in 10" :value="i" :key="i">
                {{ i === 10 ? `${i}+` : i }}
              </option>
            </select>
          </select-wrapper>
        </div>

        <div class="w-18 flex flex-col items-center">
          <span class="text-xs mb-1" v-t="'general_info_screen.reception'" />
          <select-wrapper class="w-full">
            <select v-model="receptions">
              <option v-for="i in 10" :value="i" :key="i">
                {{ i === 10 ? `${i}+` : i }}
              </option>
            </select>
          </select-wrapper>
        </div>
      </div>
    </div>

    <div class="w-full">
      <div class="w-full flex items-start mb-6">
        <div class="w-full flex flex-col mr-10">
          <label class="text-sm mb-1" v-t="'general_info_screen.property_type'" />
          <select-wrapper class="mr-2 w-full">
            <select v-model="type">
              <option 
                v-for="type in $t('general_info_screen.property_types')" 
                :value="type.value" 
                :key="type.value" 
                v-html="type.label" 
              />
            </select>
          </select-wrapper>
        </div>

        <div class="w-full flex flex-col">
          <label class="text-sm cursor-pointer mb-1" for="year-built">
            {{ $t('general_info_screen.year_built') }} <span class="text-xs" v-t="'general_info_screen.roughly'" />
          </label>
          <input 
            v-model="yearBuilt" 
            type="text" 
            id="year-built" 
            class="input w-full" 
            :placeholder="$t('general_info_screen.year_built_placeholder')" 
          />
          <error field="year_built" />
        </div>
      </div>

      <div class="w-full flex items-start mb-6">
        <div class="w-full flex flex-col mr-10">
          <label class="text-sm mb-1" v-t="'general_info_screen.plot_size'" />
          <select-wrapper class="mr-2 w-full">
            <select v-model="plotSize">
              <option 
                v-for="size in $t('general_info_screen.plot_sizes')" 
                :value="size.value" 
                :key="size.value" 
                v-html="size.label" 
              />
            </select>
          </select-wrapper>
        </div>

        <div class="w-full flex flex-col">
          <label class="text-sm mb-1" v-t="'general_info_screen.condition'" />
          <select-wrapper class="mr-2 w-full">
            <select v-model="condition">
              <option 
                v-for="cond in $t('general_info_screen.conditions')" 
                :value="cond.value" 
                :key="cond.value" 
                v-html="cond.label" 
              />
            </select>
          </select-wrapper>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col mb-6">
      <p class="mb-1 text-sm" v-t="'general_info_screen.alterations'" />
      <textarea 
        v-model="alterations" 
        class="input w-full resize-none" 
        rows="4"
        :placeholder="$t('general_info_screen.alterations_placeholder')"
      ></textarea>
      <error field="alterations" />
    </div>

    <div class="w-full max-w-sm mx-auto flex justify-center">
      <button @click="setScreen('property')" class="btn bg-secondary btn-text-color w-full flex items-center justify-center mr-4">
        {{ $t('back') }}
      </button>
      <button @click="setScreen('features')" class="btn bg-secondary btn-text-color w-full flex items-center justify-center">
        {{ $t('next') }} <icon name="arrow_right" class="w-8 h-8" />
      </button>
    </div>
  </div>
</template>

<script>
import SelectWrapper from '@/components/SelectWrapper'
import Error from '@/components/Error'
import Icon from '@/components/Icon'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'GeneralInformation',

  components: {
    Icon,
    SelectWrapper,
    Error
  },

  computed: {
    ...mapState({
      valuationType: state => state.valuationType,
      propertyBedrooms: state => state.bedrooms,
      propertyBathrooms: state => state.bathrooms,
      propertyReceptions: state => state.receptions,
      propertyType: state => state.propertyType,
      propertyYearBuilt: state => state.yearBuilt,
      propertyPlotSize: state => state.plotSize,
      propertyCondition: state => state.condition,
      propertyAlterations: state => state.alterations,
    }),

    bedrooms: {
      get () {
        return this.propertyBedrooms
      },
      set (value) {
        this.setBedrooms(value)
      }
    },

    bathrooms: {
      get () {
        return this.propertyBathrooms
      },
      set (value) {
        this.setBathrooms(value)
      }
    },

    receptions: {
      get () {
        return this.propertyReceptions
      },
      set (value) {
        this.setReceptions(value)
      }
    },

    type: {
      get () {
        return this.propertyType
      },
      set (value) {
        this.setPropertyType(value)
      }
    },

    yearBuilt: {
      get () {
        return this.propertyYearBuilt
      },
      set (value) {
        this.setYearBuilt(value)
      }
    },

    plotSize: {
      get () {
        return this.propertyPlotSize
      },
      set (value) {
        this.setPlotSize(value)
      }
    },

    condition: {
      get () {
        return this.propertyCondition
      },
      set (value) {
        this.setCondition(value)
      }
    },

    alterations: {
      get () {
        return this.propertyAlterations
      },
      set (value) {
        this.setAlterations(value)
      }
    },

  },

  methods: {
    ...mapActions([
      'setScreen',
      'setBedrooms',
      'setBathrooms',
      'setReceptions',
      'setPropertyType',
      'setYearBuilt',
      'setPlotSize',
      'setCondition',
      'setAlterations',
    ])
  }
}
</script>
