export default {
  setAlias (state, alias) {
    state.alias = alias
  },

  setHttp (state, http) {
    state.http = http
  },

  setSystem (state, system) {
    state.system = system
  },
  
  setCompletedLead (state, bool) {
    state.completedLead = bool
  },

  setValidationErrors (state, errors) {
    state.validationErrors = errors
  }, 

  setScreen (state, screen) {
    state.currentScreen = screen
  },

  setLoadingAddresses (state, bool) {
    state.loadingAddresses = bool
  },

  setCountry(state, country) {
    state.address.data.address_country = country
  },

  setAddresses(state, addresses) {
    state.addresses = addresses
  },

  setPostcode (state, postcode) {
    state.postcode = postcode
  },

  setAddress (state, address) {
    state.address = address
  },

  setType (state, type) {
    state.type = type
  },

  setName (state, name) {
    state.name = name
  },

  setPhone (state, phone) {
    state.phone = phone
  },

  setEmail (state, email) {
    state.email = email
  },

  setBedrooms(state, bedrooms) {
    state.bedrooms = bedrooms
  },

  setBathrooms(state, bathrooms) {
    state.bathrooms = bathrooms
  },

  setReceptions (state, receptions) {
    state.receptions = receptions
  },

  setPropertyType (state, type) {
    state.propertyType = type
  },

  setYearBuilt (state, year) {
    state.yearBuilt = year
  },

  setPlotSize (state, size) {
    state.plotSize = size
  },

  setCondition (state, condition) {
    state.condition = condition
  },

  setAlterations (state, alterations) {
    state.alterations = alterations
  },

  setFeatures(state, features) {
    state.features = features
  },

  setNotes (state, notes) {
    state.notes = notes
  },
  
  appendFiles (state, files) {
    state.files.push(...files)
  },

  updateFileProgress (state, { file, progress }) {
    let index = state.files.indexOf(file)
    state.files[index].progress = progress
  },

  updateFileHandled (state, { file, handled }) {
    let index = state.files.indexOf(file)
    state.files[index].handled = handled
  },

  updateFileCancel (state, { file, cancel }) {
    let index = state.files.indexOf(file)
    state.files[index].cancel = cancel
  },

  updateFileFullPath (state, { file, fullpath }) {
    let index = state.files.indexOf(file)
    state.files[index].fullpath = fullpath
  },

  removeFile (state, file) {
    let index = state.files.indexOf(file)
    if (state.files[index].cancel) {
      state.files[index].cancel()
    }
    state.files.splice(index, 1)
  },

  setAvailabilityCalendar (state, calendar) {
    state.availabilityCalendar = calendar
  },

  setOffice (state, office) {
    state.office = office
  },

  setSelectedDates (state, dates) {
    state.selectedDates = dates
  },

  setSendingLead (state, bool) {
    state.sendingLead = bool
  },

  setValuationType (state, type) {
    state.valuationType = type
  }
}