import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/lang/en.json'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en
  }
})

// Language lazy loading.
// See here for details: https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
const loadedLanguages = ['en']

function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.indexOf(lang) !== -1) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.json`)
    .then(
      messages => {
        i18n.setLocaleMessage(lang, messages.default)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      }
    )
}

// For hot loading, makes developing life easier.
// Add languages here as more become available.
if (module.hot) {
  module.hot.accept(['./lang/en.json'], function () {
    i18n.setLocaleMessage('en', require('./lang/en.json'))
  })
}

export default i18n
