<template>
  <div class="flex flex-col">
    <div class="flex items-center mb-4">
      <input 
        v-model="postcode" 
        @keyup.enter="fetchAddressesForPostcode(postcode)"
        type="text" 
        class="input w-full outline-none mr-4" 
        :placeholder="$t('addresses.enter_postcode')"
      />
      <button 
        class="btn px-3 bg-secondary btn-text-color whitespace-no-wrap flex items-center"
        :disabled="postcode.length === 0"
        @click="fetchAddressesForPostcode(postcode)"
      >
        <span class="mr-2" v-t="'addresses.find'" /> <Icon name="chevron_right" class="w-3 h-3" />
      </button>
    </div>

    <div class="w-full flex flex-col items-center justify-center">
      <transition name="transform-y-and-fade" mode="out-in">
        <loader v-if="loadingAddresses" key="1" />
        <div 
          key="2"
          v-if="!loadingAddresses && addresses.length"
          class="w-full flex flex-col"
        >
          <select-wrapper class="mb-2">
            <select v-model="address">
              <option 
                v-for="(addr, index) in addresses"
                :value="addr"
                :key="index"
              >
              {{ addr.formatted }}
              </option>
            </select>
          </select-wrapper>
        </div>
      </transition>
    </div>
    
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SelectWrapper from '@/components/SelectWrapper'
import Loader from '@/components/Loader'
import Icon from '@/components/Icon'

export default {
  name: 'AddressSelector',
  props: ['cant-find-text', 'unselected-value'],
  components: {
    SelectWrapper,
    Loader,
    Icon
  },

  computed: {
    ...mapState({
      loadingAddresses: state => state.loadingAddresses,
      addresses: state => state.addresses,
      propertyPostcode: state => state.postcode,
      propertyAddress: state => state.address,
    }),

    postcode: {
      get () {
        return this.propertyPostcode
      },
      set (val) {
        this.setPostcode(val.toUpperCase())
      }
    },

    address: {
      get () {
        return this.propertyAddress
      },
      set (val) {
        this.setAddress(val)
      }
    }
  },

  methods: {
    ...mapActions([
      'fetchAddressesForPostcode',
      'setPostcode',
      'setAddress'
    ]),
  }
}
</script>

<style scoped>
.animated {
  transition: opacity .5s, transform .5s;
}

@keyframes fadeInDown {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.fade-in-down {
  animation: 1s fadeInDown;
}
</style>
