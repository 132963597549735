<template>
  <div class="w-full h-full flex flex-col items-center justify-between">

    <div class="text-center mb-12">
      <div class="w-16 h-16 flex items-center justify-center mb-6 rounded-full text-primary border-2 border-primary mx-auto">
        <icon name="tick" class="w-8 h-8" />
      </div>

      <h1 class="text-lg uppercase mb-12">{{ $t('thank_you_screen.thank_you') }}</h1>
      <p class="italic mb-6">{{ address }}</p>
      <p><span class="text-primary" v-t="`thank_you_screen.name`"></span> {{ name }}</p>
      <p><span class="text-primary" v-t="`thank_you_screen.phone`"></span> {{ phone }}</p>
      <p><span class="text-primary" v-t="`thank_you_screen.email`"></span> {{ email }}</p>
    </div>

    <div class="w-full mb-6">
      <p class="text-sm text-center mb-1">{{ $t('thank_you_screen.outro_paragraph1', {client: office.name, office: office.town}) }}</p>
      <p class="text-sm text-center" v-t="'thank_you_screen.outro_paragraph2'" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Icon from '../components/Icon.vue'

export default {
  components: { Icon },
  name: 'ThankYou',

  computed: {
    ...mapState(['name', 'office', 'system', 'phone', 'email']),
    ...mapGetters(['valuationType', 'address'])
  },

  methods: {
    ...mapActions(['setScreen'])
  }
}
</script>
