<template>
  <div class="flex min-h-sm items-start">
    <div class="w-full" v-if="calendarLoaded">
      <div v-if="times.length > 0" class="h-full flex items-start flex-wrap">
        <div 
          v-for="(time, index) in times"
          :key="index"
          class="m-2 py-1 px-2 bg-secondary rounded flex items-center justify-center cursor-pointer"
          :class="{
            'opacity-50': value.indexOf(time) === -1
          }"
          @click="selectedOption(time)"
        >
          <span class="text-sm option-color">{{ time }}</span>
        </div>
      </div>
      <p v-else-if="availabilityCalendar.length === 0"  class="m-2 text-sm" v-t="'preferred_times_screen.once_sent'">
      <p v-else class="m-2 text-sm" v-t="'preferred_times_screen.no_availability'" />
    </div>
    <loader v-else class="self-center center-x" />
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import { mapState } from 'vuex'
import { DateTime } from 'luxon'

export default {
  name: 'AvailableTimeSlots',

  components: {
    Loader
  },

  props: {
    calendarLoaded: {
      type: Boolean
    },
    value: {
      type: Array
    },
    date: {
      type: DateTime
    }
  },

  computed: {
    ...mapState([
      'availabilityCalendar',
      'system',
      'type'
    ]),

    times () {
      /*
        Filter by the weekday and make sure the time blocks are within the date ranges.
      */
      let times = this.availabilityCalendar.filter(availability => {
        return availability.weekday === this.date.weekday &&
          DateTime.fromISO(availability.start_date) <= this.date && 
          (availability.end_date == null || DateTime.fromISO(availability.end_date) >= this.date)
      })
      /*
        Turn the time strings into numeric values excluding any seconds, 
        spliting the hours and minutes into their own values.
      */
      .map(availability => {
        let [startHour, startMinute] = availability.start_time.split(':', 2).map(t => parseInt(t))
        let [endHour, endMinute] = availability.end_time.split(':', 2).map(t => parseInt(t))
        return {
          start: { hour: startHour, minutes: startMinute },
          end: { hour: endHour, minutes: endMinute }
        }
      })
      /*
        Sort by the hour and minutes, this is very important for the mergeTimes function, 
        it needs to be in order to do a comparison with next block and check if it can be 
        merged and be removed from the array.
      */
      .sort((a, b) => {
        return a.hour > b.hour && a.minutes > b.minutes ? -1 : 1
      })    

      /* 
        We merge any time blocks that are seperate but are actually continuous lengths of time.
        eg: 3 availablity blocks: (10:00 - 15:30), (15:30 - 17:00), (17:00 - 20:00) 
        would become 1 availablity block: (10:00 - 20:00)
      */
      this.mergeTimes(times)

      /* 
        We round up any hours that do not perfectly sit on an hour.
        eg. Avilability from 10:30 would actually be availablity from 11am,
        this is so we can increment in 1 hour blocks.
      */
      this.createAvailableHours(times)

      /*
        Reduce all of the availablity blocks, flattening them into hour by hour availablity.
      */
      return times.reduce((carry, time) => {
        for (let t = time.start.hour; t < time.end.hour; t += 1) {
          carry.push(
            `${(t < 10 ? '0' : '') + t}:00`
          )
        }
        return carry
      }, [])
    }
  },

  methods: {
    mergeTimes (times) {
      // mergeTimes expects a sorted array of times,
      let length = times.length

      if (length === 1) return times

      for (let i = 0; i < length - 1; i++) {
        if (
          times[i].end.hour === times[i + 1].start.hour &&
          times[i].end.minutes === times[i + 1].start.minutes
        ) {
          times[i].end.hour = times[i + 1].end.hour
          times[i].end.minutes = times[i + 1].end.minutes
          times.splice(i + 1, 1)
          length--
        }
      }
      // IMPORTANT: There is no return because mergeTimes mutates the existing data
    },

    createAvailableHours (times) {
      for (let i = 0; i < times.length; i++) {
        if (times[i].start.minutes > 0) {
          times[i].start.hour += 1
          times[i].start.minutes = 0
        }

        if (times[i].end.minutes > 0) {
          times[i].end.minutes = 0
        }

        if (times[i].end.hour === times[i].start.hour) {
          times.splice(i, 1)
          i--
        }
      }
      // IMPORTANT: There is no return because createAvailableHours mutates the existing data
    },

    selectedOption (option) {
      if (this.value.indexOf(option) === -1) {
        if (this.value.length >= this.system.max_timeslots) {
          return
        }
        this.$emit('input', [...this.value, option])
      } else {
        this.$emit('input', this.value.filter(v => v !== option))
      }
    }
  }
}
</script>
