<template>
  <div class="p-3 border shadow-sm flex rounded flex-col">
    <span class="mb-3" v-t="'submission_errors'" />
    <ul class="list-disc list-inside text-red text-xs">
      <li v-for="(messages, error) in validationErrors" :key="error">{{ messages[0] }}</li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ValidationErrors',
  
  computed: {
    ...mapState(['validationErrors'])
  } 
}
</script>
